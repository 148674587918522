import { Injectable } from '@angular/core'
import { UserDefaultDateFormat } from './user-date-format.model'
import { User, UserPermissions } from '@agroone/entities'
import { BehaviorSubject, Observable } from 'rxjs'
import { UserNetworkService } from './user.network.service'
import { TranslateService } from '@ngx-translate/core'
import { map } from 'rxjs/operators'
import { Technician } from 'libs/entities/src/lib/dtos/technician'
import { registerLocaleData } from '@angular/common'
import localeEs from '@angular/common/locales/es'
import localeFr from '@angular/common/locales/fr'
import localeDe from '@angular/common/locales/de'
import localeIt from '@angular/common/locales/it'
import localePt from '@angular/common/locales/pt'
import localeRu from '@angular/common/locales/ru'
import localePl from '@angular/common/locales/pl'
import localeHu from '@angular/common/locales/hu'
import localeFrCa from '@angular/common/locales/fr-CA'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly localLanguages = [
    localeEs,
    localeFr,
    localeDe,
    localeIt,
    localePt,
    localeRu,
    localePl,
    localeHu,
    localeFrCa,
  ]
  public user: Observable<User>

  public get currentUserDateFormat(): UserDefaultDateFormat {
    return this.defaultDateUserSubject.getValue()
  }

  public get currentUserDateHourFormat(): UserDefaultDateFormat {
    return this.defaultDateHourUserSubject.getValue()
  }

  public techniciansSubject: BehaviorSubject<Technician[]> = new BehaviorSubject<Technician[]>(null)

  private readonly DEFAULT_DATE_FORMAT: string = 'dd/MM/yy'
  private readonly DEFAULT_HOUR_FORMAT: string = 'HH:mm'
  private userSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null)
  private usersSubject: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(null)
  private defaultDateUserSubject: BehaviorSubject<UserDefaultDateFormat> = new BehaviorSubject<UserDefaultDateFormat>(
    null
  )
  private defaultDateHourUserSubject: BehaviorSubject<UserDefaultDateFormat> =
    new BehaviorSubject<UserDefaultDateFormat>(null)

  get currentUser(): User {
    return this.userSubject.getValue()
  }

  get users() {
    return this.usersSubject.getValue()
  }

  get technicians(): Technician[] {
    return this.techniciansSubject.getValue()
  }

  constructor(private translate: TranslateService, private userNetworkService: UserNetworkService) {
    this.user = this.userSubject.asObservable()
  }

  public init(): Observable<User> {
    this.user.subscribe((user) => {
      if (user) {
        if (!user.defaultDateFormat) {
          user.defaultDateFormat = this.DEFAULT_DATE_FORMAT
        }
        this.defaultDateUserSubject.next(new UserDefaultDateFormat(user.defaultDateFormat))
        this.defaultDateHourUserSubject.next(
          new UserDefaultDateFormat(user.defaultDateFormat + ' ' + this.DEFAULT_HOUR_FORMAT)
        )
        this.localLanguages.forEach((language) => registerLocaleData(language))
        this.setLanguage(user.language)
      }
    })
    return this.getCurrentUser()
  }

  public getCurrentUser(): Observable<User> {
    return this.userNetworkService.getUser().pipe(
      map((user: User) => {
        const newUser: User = new User(user)
        if (user) {
          this.userSubject.next(user)
        }
        return newUser
      })
    )
  }

  public getTechnicians(): Observable<Technician[]> {
    return this.userNetworkService.getTechnicians()
  }

  public setLanguage(language: string) {
    this.translate.setDefaultLang(language)
    this.translate.use(language)
  }

  public hasPermission(user: User, permission: UserPermissions) {
    return user.permissions.includes(permission)
  }
}
